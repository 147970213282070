/* eslint-disable sort-keys-fix/sort-keys-fix */
import { DefaultGenerics, Route } from "@tanstack/react-location-lite-experimental"
import { lazy } from "react"
import { NotFoundError } from "src/shared/helpers/errors"

// Misc
const App = lazy(() => import("./App"))

// Main
const Main = lazy(() => import("./App/Main"))
const Pool = lazy(() => import("./App/Main/Pool"))
const Dashboard = lazy(() => import("./App/Main/Pool/dashboard/Dashboard"))
const SourcesList = lazy(() => import("./App/Main/Pool/sources/SourcesList"))
const SourceView = lazy(() => import("./App/Main/Pool/sources/SourceView"))
const PodsList = lazy(() => import("./App/Main/Pool/pods/PodsList"))
const PodView = lazy(() => import("./App/Main/Pool/pods/PodView"))
const DestinationsList = lazy(() => import("./App/Main/Pool/destinations/DestinationsList"))
const DestinationView = lazy(() => import("./App/Main/Pool/destinations/DestinationView"))
const ScheduledEventsList = lazy(() => import("./App/Main/Pool/scheduler/ScheduledEventsList"))
const Settings = lazy(() => import("./App/Main/Pool/settings/Settings"))

// Wizard
const DoneStep = lazy(() => import("./App/Wizard/done/DoneStep"))
const DestinationsStep = lazy(() => import("./App/Wizard/destinations/DestinationsStep"))
const InputStreamsStep = lazy(() => import("./App/Wizard/input_streams/InputStreamsStep"))
const PointsOfDistributionStep = lazy(() => import("./App/Wizard/pods/PointsOfDistributionStep"))
const SourceAvailabilityStep = lazy(
  () => import("./App/Wizard/source_availability/SourceAvailabilityStep")
)
const SchedulerStep = lazy(() => import("./App/Wizard/scheduler/SchedulerStep"))
const SourceLocationStep = lazy(() => import("./App/Wizard/source_location/SourceLocationStep"))
const Wizard = lazy(() => import("./App/Wizard"))

const ThrowNotFound = () => {
  throw new NotFoundError()
}

export const linkTree: Route<DefaultGenerics> = {
  path: "link",
  element: <App />,
  children: [
    {
      path: "pools",
      element: <Main />,
      children: [
        {
          path: ":poolUuid",
          element: <Pool />,
          children: [
            { path: "/", element: <Dashboard /> },
            { path: "destinations", element: <DestinationsList /> },
            { path: "pods/:podUuid", element: <PodView /> },
            { path: "pods", element: <PodsList /> },
            { path: "scheduler", element: <ScheduledEventsList /> },
            { path: "settings", element: <Settings /> },
            {
              path: "sources/:sourceUuid/pods/:podUuid/destinations/:destinationUuid",
              element: <DestinationView />,
            },
            { path: "sources/:sourceUuid", element: <SourceView /> },
            { path: "sources", element: <SourcesList /> },
          ],
        },
      ],
    },
    {
      path: "wizard/:poolUuid/sources/:sourceUuid",
      element: <Wizard />,
      children: [
        { path: "destinations", element: <DestinationsStep /> },
        { path: "done", element: <DoneStep /> },
        { path: "input_streams", element: <InputStreamsStep /> },
        { path: "pods", element: <PointsOfDistributionStep /> },
        { path: "scheduler", element: <SchedulerStep /> },
        { path: "source_availability", element: <SourceAvailabilityStep /> },
        { path: "source_location", element: <SourceLocationStep /> },
        { element: <ThrowNotFound /> },
      ],
    },
  ],
}
