import { Navigate, useLocation } from "@tanstack/react-location-lite-experimental"

const NoTrailingSlash = () => {
  const { search, hash, pathname, key } = useLocation().current

  if (pathname !== "/" && pathname.endsWith("/")) {
    return <Navigate replace {...{ hash, key, search, to: pathname.slice(0, -1) }} />
  }

  return null
}

export default NoTrailingSlash
