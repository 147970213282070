/* eslint-disable sort-keys-fix/sort-keys-fix */
import { DefaultGenerics, Outlet, Route } from "@tanstack/react-location-lite-experimental"
import { lazy } from "react"
import { linkTree } from "src/link/routes"
import { playTree } from "src/play/routes"
import { truthy } from "src/shared/helpers/arrays"

// Misc
const App = lazy(() => import("./App"))
const DesignSystem = lazy(() => import("./App/DesignSystem"))
const Organization = lazy(() => import("./App/Organization"))
const WithStripe = lazy(() => import("src/shared/components/utils/WithStripe"))

// Landings
const InviteRequest = lazy(() => import("./components/landings/InviteRequest"))
const Invitation = lazy(() => import("./components/landings/Invitation"))
const NewOrganization = lazy(() => import("./components/landings/NewOrganization"))
const NotFound = lazy(() => import("src/shared/components/landings/NotFound"))

// Billing
const BillingHome = lazy(() => import("./App/Billing/BillingHome"))
const CostDetails = lazy(() => import("./App/Billing/CostDetails"))
const EditBillingDetails = lazy(() => import("./App/Billing/EditBillingDetails"))
const EditPaymentDetails = lazy(() => import("./App/Billing/EditPaymentDetails"))

// Subscription
const SubscribeBillingStep1 = lazy(() => import("./App/Subscription/SubscribeBillingStep1"))
const SubscribeBillingStep2 = lazy(() => import("./App/Subscription/SubscribeBillingStep2"))
const SubscribeIntro = lazy(() => import("./App/Subscription/SubscribeIntro"))
const SubscribePayment = lazy(() => import("./App/Subscription/SubscribePayment"))
const SubscribeUpgrade = lazy(() => import("./App/Subscription/SubscribeUpgrade"))

// Organization settings
const ViewOrganization = lazy(() => import("./App/Organization/ViewOrganization"))
const AddApiKey = lazy(() => import("./App/Organization/ViewOrganization/apiKeys/AddApiKey"))
const ApiKeys = lazy(() => import("./App/Organization/ViewOrganization/apiKeys/ApiKeys"))
const EditApiKey = lazy(() => import("./App/Organization/ViewOrganization/apiKeys/EditApiKey"))
const SsoSetup = lazy(() => import("./App/Organization/ViewOrganization/sso/SsoSetup"))

// Usage
const Usage = lazy(() => import("./App/Usage"))

// Design system
const Avatars = lazy(() => import("./App/DesignSystem/base/Avatars"))
const Buttons = lazy(() => import("./App/DesignSystem/base/Buttons"))
const Forms = lazy(() => import("./App/DesignSystem/base/Forms"))
const Progress = lazy(() => import("./App/DesignSystem/base/Progress"))
const Tables = lazy(() => import("./App/DesignSystem/base/Tables"))
const Tags = lazy(() => import("./App/DesignSystem/base/Tags"))
const WiredForms = lazy(() => import("./App/DesignSystem/wired/WiredForms"))

export const portalTree: Route<DefaultGenerics> = {
  path: "/",
  element: <App />,
  children: [
    import.meta.env.DEV && {
      path: "design_system",
      element: <DesignSystem />,
      children: [
        {
          path: "base",
          children: [
            { path: "avatars", element: <Avatars /> },
            { path: "buttons", element: <Buttons /> },
            { path: "progress", element: <Progress /> },
            { path: "forms", element: <Forms /> },
            { path: "tables", element: <Tables /> },
            { path: "tags", element: <Tags /> },
          ],
        },
        {
          path: "wired",
          children: [{ path: "forms", element: <WiredForms /> }],
        },
      ],
    },
    { path: "invitation/:invitationCode", element: <Invitation /> },
    {
      path: "organizations/new",
      element: <NewOrganization />,
    },
    {
      path: "organizations/:orgUuid",
      element: <Organization />,
      children: [
        {
          path: "billing",
          children: [
            { path: "/", element: <BillingHome /> },
            { path: "costs", element: <CostDetails /> },
            { path: "editbilling", element: <EditBillingDetails /> },
            { path: "editpayment", element: <EditPaymentDetails /> },
            { element: <NotFound /> },
          ],
          element: (
            <WithStripe>
              <Outlet />
            </WithStripe>
          ),
        },
        {
          path: "invite_request/:userUuid",
          element: <InviteRequest />,
        },
        {
          path: "organization",
          children: [
            { path: "/", element: <ViewOrganization /> },
            { path: "apikeys/new", element: <AddApiKey /> },
            { path: "apikeys/:apiKeyUuid", element: <EditApiKey /> },
            { path: "apikeys", element: <ApiKeys /> },
            { path: "sso", element: <SsoSetup /> },
            { element: <NotFound /> },
          ],
        },
        {
          path: "subscribe",
          children: [
            { path: "/", element: <SubscribeIntro /> },
            { path: "billing_step_1", element: <SubscribeBillingStep1 /> },
            { path: "billing_step_2", element: <SubscribeBillingStep2 /> },
            { path: "payment", element: <SubscribePayment /> },
            {
              path: "upgrade",
              children: [
                { path: "/", element: <SubscribeUpgrade /> },
                { path: "billing_step_1", element: <SubscribeBillingStep1 /> },
                { path: "billing_step_2", element: <SubscribeBillingStep2 /> },
                { path: "payment", element: <SubscribePayment /> },
              ],
            },
            {
              element: <NotFound />,
            },
          ],
          element: (
            <WithStripe>
              <Outlet />
            </WithStripe>
          ),
        },
        {
          path: "usage",
          element: <Usage />,
        },
        linkTree,
        playTree,
        {
          element: <NotFound />,
        },
      ],
    },
    {
      element: <NotFound />,
    },
  ].filter(truthy),
}
