import { QueryErrorResetBoundary } from "@tanstack/react-query"
import { ReactNode, Suspense } from "react"
import { ErrorBoundary, FallbackProps } from "react-error-boundary"
import Loader from "../base/misc/Loader"

interface Props {
  children: ReactNode
  ErrorFallback: ({ error, resetErrorBoundary }: FallbackProps) => JSX.Element | null
}

export const WithErrorBoundary = ({ children, ErrorFallback }: Props) => (
  <QueryErrorResetBoundary>
    {({ reset }) => (
      <ErrorBoundary
        fallbackRender={({ error, resetErrorBoundary }) => (
          <ErrorFallback error={error} resetErrorBoundary={resetErrorBoundary} />
        )}
        onReset={reset}
      >
        <Suspense fallback={<Loader isFullPage />}>{children}</Suspense>
      </ErrorBoundary>
    )}
  </QueryErrorResetBoundary>
)
