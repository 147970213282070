import { useCallback, useLayoutEffect } from "react"
import { useStore } from "zustand"
import { createStore } from "zustand/vanilla"

type State = {
  isModifierKeyDown: boolean
}

export const keyboardStore = createStore<State>((set, get) => ({
  isModifierKeyDown: false,
}))

export const useKeyboardStore = <T>(selector: (state: State) => T) =>
  useStore(keyboardStore, selector)

export const useKeyboardDetection = () => {
  const handleKeydown = useCallback((e: KeyboardEvent) => {
    if ((e.ctrlKey || e.altKey) && !keyboardStore.getState().isModifierKeyDown) {
      keyboardStore.setState({ isModifierKeyDown: true })
    }
  }, [])

  const handleKeyup = useCallback((e: KeyboardEvent) => {
    if (!e.ctrlKey && !e.altKey && keyboardStore.getState().isModifierKeyDown) {
      keyboardStore.setState({ isModifierKeyDown: false })
    }
  }, [])

  useLayoutEffect(() => {
    document.addEventListener("keydown", handleKeydown)
    document.addEventListener("keyup", handleKeyup)
    return () => {
      document.removeEventListener("keydown", handleKeydown)
      document.removeEventListener("keyup", handleKeyup)
    }
  }, [handleKeydown, handleKeyup])
}
