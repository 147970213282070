import "src/shared/styles/legacy.scss"
import "src/shared/styles/toastify.scss"
import "src/shared/styles/index.css"
import { Auth0Provider } from "@auth0/auth0-react"
import { captureException, init } from "@sentry/browser"
import { Outlet, ReactLocation, Router } from "@tanstack/react-location-lite-experimental"
import { QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { lazy, Suspense } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { ToastContainer } from "react-toastify"
import Loader from "src/shared/components/base/misc/Loader"
import NoTrailingSlash from "src/shared/components/utils/NoTrailingSlash"
import { checkIsFromBackoffice, isFromBackOffice } from "src/shared/helpers/backoffice"
import { queryClient } from "src/shared/queries/client"
import { useKeyboardDetection } from "src/shared/stores/keyboard"
import { portalTree } from "./portal/routes"
import AuthenticationRequired from "./shared/components/utils/AuthenticationRequired"
import { WithErrorBoundary } from "./shared/components/utils/WithErrorBoundary"

const ErrorFallback = lazy(() => import("src/portal/components/wired/misc/ErrorFallback"))
const FromBackOfficeBanner = lazy(
  () => import("./shared/components/wired/banners/FromBackOfficeBanner")
)
const FromBackOfficeBorder = lazy(
  () => import("./shared/components/base/misc/FromBackOfficeBorder")
)
const Logout = lazy(() => import("./shared/components/utils/Logout"))

// Configuring sentry's dynamic options.
const sentryDsn = import.meta.env.REACT_APP_SENTRY_DSN

if (sentryDsn) {
  init({
    dsn: sentryDsn,
    environment: import.meta.env.REACT_APP_SENTRY_ENV || "default",
    release: import.meta.env.REACT_APP_SENTRY_RELEASE,
  })
}

const reactLocation = new ReactLocation()

checkIsFromBackoffice()

const getAuth0ClientId = () => {
  const backOfficeId = import.meta.env.REACT_APP_AUTH0_CLIENT_ID_QX_BACKOFFICE
  const appId = import.meta.env.REACT_APP_AUTH0_CLIENT_ID
  return isFromBackOffice() ? backOfficeId : appId
}

const routes = [{ element: <Logout />, path: "logout" }, portalTree]

const App = () => {
  useKeyboardDetection()
  const fromBackOffice = isFromBackOffice()

  return (
    <ErrorBoundary
      fallbackRender={({ error }) => <Loader error={error} isCover />}
      onError={error => captureException(error)}
    >
      <Suspense fallback={<Loader isCover />}>
        <Auth0Provider
          authorizationParams={{
            audience: import.meta.env.REACT_APP_AUTH0_AUDIENCE,
            redirect_uri: window.location.origin,
          }}
          cacheLocation={fromBackOffice ? "memory" : "localstorage"}
          clientId={getAuth0ClientId()}
          domain={import.meta.env.REACT_APP_AUTH0_DOMAIN}
          issuer={import.meta.env.REACT_APP_AUTH0_ISSUER}
          useFormData
          useRefreshTokens
          useRefreshTokensFallback
          onRedirectCallback={appState => {
            const returnTo = appState?.returnTo
            if (returnTo) reactLocation.history.push(returnTo)
          }}
        >
          <QueryClientProvider client={queryClient}>
            <AuthenticationRequired>
              <Router location={reactLocation} routes={routes}>
                <NoTrailingSlash />
                <WithErrorBoundary ErrorFallback={ErrorFallback}>
                  {fromBackOffice && <FromBackOfficeBanner />}
                  <Outlet />
                  {fromBackOffice && <FromBackOfficeBorder />}
                </WithErrorBoundary>
                <ToastContainer
                  closeButton={({ closeToast }) => (
                    <button className="delete" onClick={closeToast} />
                  )}
                  closeOnClick={false}
                  hideProgressBar
                  icon={false}
                  pauseOnFocusLoss
                  pauseOnHover
                  theme="colored"
                />
              </Router>
            </AuthenticationRequired>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </QueryClientProvider>
        </Auth0Provider>
      </Suspense>
    </ErrorBoundary>
  )
}

export default App
