/* eslint-disable sort-keys-fix/sort-keys-fix */
import { DefaultGenerics, Route } from "@tanstack/react-location-lite-experimental"
import { lazy } from "react"

// Misc
const App = lazy(() => import("./App"))

// Landings
const NotFound = lazy(() => import("src/shared/components/landings/NotFound"))

// Main
const Main = lazy(() => import("./App/Main"))
const Pool = lazy(() => import("./App/Main/Pool"))
const Status = lazy(() => import("./App/Main/Pool/status/Status"))
const Analytics = lazy(() => import("./App/Main/Pool/analytics/Analytics"))
const CloudSettings = lazy(() => import("./App/Main/Pool/cloudsettings/CloudSettings"))
const PoolLocationForm = lazy(
  () => import("./App/Main/Pool/cloudsettings/CloudSettings/PoolLocationForm")
)
const AddPoolApiKey = lazy(
  () => import("./App/Main/Pool/cloudsettings/CloudSettings/apiKeys/AddApiKey")
)
const PoolApiKeys = lazy(
  () => import("./App/Main/Pool/cloudsettings/CloudSettings/apiKeys/ApiKeys")
)
const EditPoolApiKey = lazy(
  () => import("./App/Main/Pool/cloudsettings/CloudSettings/apiKeys/EditApiKey")
)
const EventLog = lazy(() => import("./App/Main/Pool/eventlog/EventLog"))
const AddInput = lazy(() => import("./App/Main/Pool/inputs/AddInput"))
const Inputs = lazy(() => import("./App/Main/Pool/inputs/Inputs"))
const ViewInput = lazy(() => import("./App/Main/Pool/inputs/ViewInput"))
const AddStream = lazy(() => import("./App/Main/Pool/inputs/AddStream"))
const EditStream = lazy(() => import("./App/Main/Pool/inputs/EditStream"))
const AddProcessing = lazy(() => import("./App/Main/Pool/processings/AddProcessing"))
const EditProcessing = lazy(() => import("./App/Main/Pool/processings/EditProcessing"))
const Processings = lazy(() => import("./App/Main/Pool/processings/Processings"))
const PublishingPoints = lazy(() => import("./App/Main/Pool/publishingpoints/PublishingPoints"))
const AddRule = lazy(() => import("./App/Main/Pool/rules/AddRule"))
const EditRule = lazy(() => import("./App/Main/Pool/rules/EditRule"))
const Rules = lazy(() => import("./App/Main/Pool/rules/Rules"))
const AddTarget = lazy(() => import("./App/Main/Pool/targets/AddTarget"))
const EditTarget = lazy(() => import("./App/Main/Pool/targets/EditTarget"))
const Targets = lazy(() => import("./App/Main/Pool/targets/Targets"))
const Programs = lazy(() => import("./App/Main/Pool/catchup/Programs"))
const AddProgram = lazy(() => import("./App/Main/Pool/catchup/Programs/AddProgram"))
const EditProgram = lazy(() => import("./App/Main/Pool/catchup/Programs/EditProgram"))
const AddVodAsset = lazy(() => import("./App/Main/Pool/catchup/VodAssets/AddVodAsset"))
const AddEncryption = lazy(() => import("./components/features/targets/encryptions/AddEncryption"))
const EditEncryption = lazy(
  () => import("./components/features/targets/encryptions/EditEncryption")
)

// Wizard
const Wizard = lazy(() => import("./App/Wizard"))
const WizardCloudSettings = lazy(() => import("./App/Wizard/cloudsettings/CloudSettings"))
const WizardDone = lazy(() => import("./App/Wizard/done/Done"))
const WizardAddInput = lazy(() => import("./App/Wizard/inputs/AddInput"))
const WizardEditInput = lazy(() => import("./App/Wizard/inputs/EditInput"))
const WizardInputs = lazy(() => import("./App/Wizard/inputs/Inputs"))
const WizardAddProcessing = lazy(() => import("./App/Wizard/processings/AddProcessing"))
const WizardEditProcessing = lazy(() => import("./App/Wizard/processings/EditProcessing"))
const WizardProcessings = lazy(() => import("./App/Wizard/processings/Processings"))
const WizardPublishingPoints = lazy(() => import("./App/Wizard/publishingpoints/PublishingPoints"))
const WizardAddTarget = lazy(() => import("./App/Wizard/targets/AddTarget"))
const WizardEditTarget = lazy(() => import("./App/Wizard/targets/EditTarget"))
const WizardTargets = lazy(() => import("./App/Wizard/targets/Targets"))

export const playTree: Route<DefaultGenerics> = {
  path: "play",
  element: <App />,
  children: [
    {
      path: "pools",
      element: <Main />,
      children: [
        {
          path: ":poolUuid",
          element: <Pool />,
          children: [
            { path: "/", element: <Status /> },
            { path: "analytics", element: <Analytics /> },
            { path: "catchup/programs/new", element: <AddProgram /> },
            { path: "catchup/programs/:programUuid/assets/new", element: <AddVodAsset /> },
            { path: "catchup/programs/:programUuid", element: <EditProgram /> },
            { path: "catchup/programs", element: <Programs /> },
            { path: "eventlog", element: <EventLog /> },
            { path: "inputs/:inputUuid/streams/new/:type", element: <AddStream /> },
            { path: "inputs/:inputUuid/streams/:streamUuid", element: <EditStream /> },
            { path: "inputs/new", element: <AddInput /> },
            { path: "inputs/:inputUuid", element: <ViewInput /> },
            { path: "inputs", element: <Inputs /> },
            { path: "processings/new", element: <AddProcessing /> },
            { path: "processings/:processingUuid", element: <EditProcessing /> },
            { path: "processings", element: <Processings /> },
            { path: "publishingpoints", element: <PublishingPoints /> },
            { path: "rules/:ruleType/new", element: <AddRule /> },
            { path: "rules/:ruleType/:ruleUuid", element: <EditRule /> },
            { path: "rules", element: <Rules /> },
            { path: "settings/apikeys/new", element: <AddPoolApiKey /> },
            { path: "settings/apikeys/:apiKeyUuid", element: <EditPoolApiKey /> },
            { path: "settings/apikeys", element: <PoolApiKeys /> },
            { path: "settings/poolLocation", element: <PoolLocationForm /> },
            { path: "settings", element: <CloudSettings /> },
            { path: "targets/new/encryptions/new", element: <AddEncryption /> },
            { path: "targets/new/encryptions/:encryptionUuid", element: <EditEncryption /> },
            { path: "targets/new", element: <AddTarget /> },
            { path: "targets/:targetUuid/encryptions/new", element: <AddEncryption /> },
            {
              path: "targets/:targetUuid/encryptions/:encryptionUuid",
              element: <EditEncryption />,
            },
            { path: "targets/:targetUuid", element: <EditTarget /> },
            { path: "targets", element: <Targets /> },
            { element: <NotFound /> },
          ],
        },
      ],
    },
    {
      path: "wizard",
      element: <Wizard />,
      children: [
        { path: "/", element: <WizardCloudSettings /> },
        { path: ":poolUuid/inputs/new", element: <WizardAddInput /> },
        { path: ":poolUuid/inputs/:inputUuid", element: <WizardEditInput /> },
        { path: ":poolUuid/inputs", element: <WizardInputs /> },
        { path: ":poolUuid/processings/new", element: <WizardAddProcessing /> },
        { path: ":poolUuid/processings/:processingUuid", element: <WizardEditProcessing /> },
        { path: ":poolUuid/processings", element: <WizardProcessings /> },
        { path: ":poolUuid/publishingpoints", element: <WizardPublishingPoints /> },
        { path: ":poolUuid/targets/new", element: <WizardAddTarget /> },
        { path: ":poolUuid/targets/:targetUuid", element: <WizardEditTarget /> },
        { path: ":poolUuid/targets", element: <WizardTargets /> },
        { path: ":poolUuid/done", element: <WizardDone /> },
        {
          element: <NotFound />,
        },
      ],
    },
    {
      element: <NotFound />,
    },
  ],
}
